import { useRouter } from 'next/router';
import { Button, Heading } from '@lululemon/ecom-pattern-library';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './not-found.module.scss';

type Props = {
  isFeatureFlagError?: boolean;
  isCartError?: boolean;
};

const NotFound = ({ isFeatureFlagError, isCartError }: Props) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { resetCart } = useCart();

  const routeErrorHandler = () => {
    if (router.query && Boolean(router.query.isCartAlreadyOrdered)) {
      resetCart();
    }
    if (!isCartError) {
      router.push('/');
    } else {
      router.reload();
    }
  };

  const errorMessage = () => {
    switch (true) {
      case isFeatureFlagError:
        return {
          title: formatMessage({
            id: 'page.unavailable',
            defaultMessage: 'Page currently unavailable',
          }),
          description: (
            <>
              {formatMessage({
                id: 'page.unavailable.desc',
                defaultMessage:
                  'We’re currently updating this page, it shouldn’t take long. If you need immediate assistance, please reach out to your account manager.',
              })}
            </>
          ),
        };
      case isCartError:
        return {
          title: formatMessage({
            id: 'page.somethingWentWrong',
            defaultMessage: 'Something went wrong.',
          }),
          description: formatMessage({
            id: 'page.somethingWentWrong.desc',
            defaultMessage: 'There was an issue loading your cart, try again or try refreshing the page.',
          }),
          buttonLabel: formatMessage({
            id: 'page.somethingWentWrong.btn',
            defaultMessage: 'Try Again',
          }),
        };
      default:
        return {
          title: formatMessage({
            id: 'page.somethingWentWrong',
            defaultMessage: 'Something went wrong.',
          }),
          description: formatMessage({
            id: 'page.somethingWentWrong.desc.generic',
            defaultMessage: 'There was an issue loading the page, try refreshing or go back to Homepage.',
          }),
          buttonLabel: formatMessage({
            id: 'page.somethingWentWrong.homebtn',
            defaultMessage: 'Go to homepage',
          }),
        };
    }
  };

  const { title, description, buttonLabel } = errorMessage();

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.errorContainer} data-testid="error-container_test-id">
        <div className={styles.messageContainer}>
          <div className={styles.errorText}>
            <Heading className="lll-text-small" tag="h1">
              {title}
            </Heading>
          </div>
          <div className={styles.errorDesc}>
            <p className={`lll-text-button lll-font-weight-regular normal-case ${isFeatureFlagError && 'text-center'}`}>
              {description}
            </p>
          </div>

          {!isFeatureFlagError && (
            <Button kind="secondary" block={true} onClick={routeErrorHandler}>
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
