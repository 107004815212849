import Logger from 'helpers/logger';
import { Component, ErrorInfo, PropsWithChildren } from 'react';
import NotFound from 'ui/not-found';

type Props = PropsWithChildren<Record<string, never>>;

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Logger.error(`error: ${JSON.stringify(error)}, errorInfo: ${JSON.stringify(errorInfo)}`);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound />;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
